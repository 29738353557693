<template>
  <v-container>
    <!-- pageHeader -->
    <v-row>
      <v-col cols="8">
        <h1>Alternative Destinations</h1>
      </v-col>
      <v-col cols="4" align="end">
        <v-btn color="primary" text @click="edit(editedItem)"> Edit </v-btn>
        <v-btn
          color="primary"
          class="mr-2"
          text
          @click="deleteItem(editedItem)"
        >
          Delete
        </v-btn>
        <v-btn
          color="primary"
          depressed
          @click="$router.go(-1)"
          :loading="$store.state.loading"
          :disabled="$store.state.loading"
        >
          <v-icon left>mdi-arrow-left</v-icon>
          Back</v-btn
        >
      </v-col>
    </v-row>
    <!-- pageHeader -->
    <!-- Alternative destination -->
    <v-container>
      <div class="destination-detail">
        <v-row>
          <v-col cols="12" md="6">
            <v-card-text>
              <v-img
                :src="editedItem.image"
                lazy-src="https://picsum.photos/id/11/10/6"
                max-height="350"
              /> </v-card-text
          ></v-col>
          <v-col cols="12" md="6">
            <v-card-text>
              <p class="title">Name</p>
              <p class="text-capitalize">{{ editedItem.name }}</p>
              <p class="title">Night and Guest</p>
              <p class="text-capitalize">
                {{ editedItem.nights }} Nights, 1-{{ editedItem.guests }} Guests
              </p>
              <p class="title">Inventorys</p>
              <p class="text-capitalize">
                {{ editedItem.inventoryId ? editedItem.inventoryId.length : 0 }}
              </p>
            </v-card-text>
          </v-col>
        </v-row>
      </div>
      <v-row>
        <v-col cols="12" md="12" v-if="inventorysAssigned.length > 0">
          <v-data-table
            dense
            :headers="[
              { text: 'Resort ID', value: 'id' },
              { text: 'Resort Name', value: 'resortName' },
              { text: 'Destination', value: 'destination' },
              { text: 'Source', value: 'source' },
              { text: 'Date Created', value: 'dateSubmitted' },
            ]"
            
            :items="inventorysAssigned"
            :items-per-page="-1"
            sortBy="id"
            :loading="loading"
            class="border"
            :search="propertySearch"
          >
            <!-- title -->
            <template v-slot:top>
              <v-toolbar flat>
                <v-toolbar-title>Inventory Assign</v-toolbar-title>
                <v-spacer />
                <v-text-field
                  append-icon="mdi-magnify"
                  label="Search Properties"
                  single-line
                  hide-details
                  dense
                  outlined
                  v-model="propertySearch"
                  clearable
                  style="max-width: 500px"
                  :loading="loading"
                  :disabled="loading"
                ></v-text-field>
              </v-toolbar>
            </template>
            <!-- items -->
            <template v-slot:item="data">
              <template>
                <tr
                  style="cursor: pointer"
                  @click="onViewInventoryListing(data.item)"
                >
                  <td>
                    {{ data.item.id.toUpperCase() }}
                  </td>
                  <td>
                    {{ data.item.resortName }}
                  </td>
                  <td class="text-capitalize">
                    {{ data.item.destination }}
                  </td>
                  <td>{{ data.item.source }}</td>
                  <td>
                    {{
                      formatDate(data.item.dateSubmitted) +
                      " (" +
                      diffDays(data.item.dateSubmitted) +
                      " days ago)"
                    }}
                  </td>
                </tr>
              </template>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
      <div v-if="dialog">
        <v-dialog
          v-model="dialog"
          width="50%"
          transition="dialog-bottom-transition"
        >
          <v-card tile>
            <v-card-text class="text--primary mt-6">
              <!-- form -->
              <v-form ref="form" @submit.prevent="save">
                <v-card-title>
                  <span class="text-h5">{{ formTitle }} </span>
                </v-card-title>

                <v-card-text>
                  <v-container>
                    <v-row class="mt-2">
                      <v-col cols="12" md="6">
                        <v-text-field
                          v-model="editedItem.name"
                          label="Name"
                          :rules="[(v) => !!v || 'Field Required']"
                          :loading="loading"
                          :disabled="loading"
                        ></v-text-field></v-col
                      ><v-col cols="12" md="6">
                          <v-text-field
                        :loading="loading"
                        :disabled="loading"
                        v-model.trim="editedItem.nights"
                        label="Number of Nights"
                        :rules="[isNumberOnly]"
                      >
                      </v-text-field>
                        </v-col
                      >
                      <v-col cols="12" md="6"
                        >
                          <v-text-field
                        :loading="loading"
                        :disabled="loading"
                        v-model.trim="editedItem.guests"
                        label="Guest"
                        :rules="[isNumberOnly]"
                      >
                      </v-text-field>
                        </v-col
                      >
                      <v-col cols="12" md="6" class="d-flex"
                        ><v-autocomplete
                          :loading="loading"
                          :disabled="loading"
                          :items="inventoryProperty"
                          :rules="[(v) => !!v || 'Field Required']"
                          item-text="id"
                          v-model.trim="editedItem.inventoryId"
                          label="Assign Inventorys"
                          multiple
                        >
                          <template v-slot:prepend-item>
                            <v-list-item
                              ripple
                              @mousedown.prevent
                              @click="inventoryToggle"
                            >
                              <v-list-item-action>
                                <v-icon
                                  :color="
                                    editedItem.inventoryId.length > 0
                                      ? 'indigo darken-4'
                                      : ''
                                  "
                                >
                                  {{ inventoryIcon }}
                                </v-icon>
                              </v-list-item-action>
                              <v-list-item-content>
                                <v-list-item-title>
                                  Select All
                                </v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                            <v-divider class="mt-2"></v-divider>
                          </template>
                          <template v-slot:selection="{ item, index }">
                            <v-chip v-if="index < 3">
                              <span>{{ item.id }}</span>
                            </v-chip>
                          </template></v-autocomplete
                        >
                        <span
                          v-if="editedItem.inventoryId.length > 3"
                          class="grey--text des-length"
                        >
                          +{{ editedItem.inventoryId.length - 3 }}
                        </span>
                      </v-col></v-row
                    >

                    <v-row class="mt-2">
                      <v-col cols="12" md="6">
                        <v-img
                          v-if="typeof editedItem.image == 'string'"
                          max-height="212"
                          max-width="291"
                          :src="editedItem.image"
                        ></v-img>
                        <v-img
                          v-else-if="editedItem.image"
                          max-height="212"
                          max-width="291"
                          :src="editedItem.image.downloadUrl"
                        ></v-img>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-btn
                          color="primary"
                          :style="{ width: `100%` }"
                          :disabled="loading"
                          @click="$refs.fileInput.click()"
                          :loading="loading"
                        >
                          Upload
                          {{
                            docButtonDisable || editedItem.image
                              ? "new image"
                              : "a image"
                          }}
                          <input
                            ref="fileInput"
                            type="file"
                            accept=".jpg,.jpeg"
                            style="display: none"
                            @change="onFileSelected"
                          />
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="close"
                    :loading="loading"
                    :disabled="loading"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="save"
                    :loading="loading"
                    :disabled="loading || !editedItem.image"
                  >
                    Save
                  </v-btn>
                </v-card-actions>
              </v-form></v-card-text
            ></v-card
          ></v-dialog
        >
      </div>
      <v-dialog v-model="dialogDelete" max-width="500px">
        <v-card>
          <v-card-title class="text-h5"
            >Are you sure you want to delete this item?</v-card-title
          >
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              @click="closeDelete"
              :loading="loading"
              :disabled="loading"
              >Cancel</v-btn
            >
            <v-btn
              color="blue darken-1"
              text
              @click="deleteItemConfirm"
              :loading="loading"
              :disabled="loading"
              >OK</v-btn
            >
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </v-container>
</template>

<script>
import { portalsApp, mainApp } from "@/firebase";
const inventoryProperty = portalsApp
  .firestore()
  .collection("inventoryListings");
import moment from "moment";
export default {
  components: {},
  async mounted() {
    await this.$store.dispatch("setLoading", true);
    await this.$bind(
      "editedItem",
      portalsApp
        .firestore()
        .collection("alternativeDestinations")
        .doc(this.$route.params.id)
    );
    await this.$bind("inventoryProperty", inventoryProperty);
    // bind alternativeDestinations

    await this.$store.dispatch("setLoading", false);
  },

  data: () => ({
    propertySearch: "",
    inventoryProperty: [],
    loading: false,
    editedItem: {},
    dialog: false,
    dialogDelete: false,
  }),
  watch: {
    async dialog(val) {
      if (val == false) {
        let pack = await portalsApp
          .firestore()
          .collection("alternativeDestinations")
          .doc(this.$route.params.id)
          .get();
        this.editedItem = pack.data();
      }
    },
  },
  computed: {
    isNumberOnly() {
      return (v) => {
        if (!v) return "Field Required";
        if (!/^[0-9]+$/.test(v))return "Numbers Only"
        return true;
      }
    },
    inventorysAssigned() {
      let items = [];
      this.editedItem.inventoryId = typeof this.editedItem.inventoryId == "string" ? JSON.parse(this.editedItem.inventoryId) : this.editedItem.inventoryId;
      if (this.inventoryProperty.length && this.editedItem.inventoryId) {
        this.editedItem.inventoryId.map((el) => {
          const  inventoryList= this.inventoryProperty.filter((inventory) => inventory.id == el)
          if(inventoryList.length) items.push(inventoryList[0])
        });
      }
    return items;
    },
    formTitle() {
      return this.editedIndex === -1 ? "New Destination" : "Edit Destination";
    },
    docButtonDisable() {
      return this.docName !== "";
    },
    likesSomeInventory() {
      return this.editedItem.inventoryId.length > 0 && !this.likesAllInventory;
    },
    inventoryIcon() {
      if (this.likesAllInventory) return "mdi-close-box";
      if (this.likesSomeInventory) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
    likesAllInventory() {
      return (
        this.editedItem.inventoryId.length === this.inventoryProperty.length
      );
    },
  },
  methods: {
    inventoryToggle() {
      this.$nextTick(() => {
        if (this.likesAllInventory) {
          this.editedItem.inventoryId = [];
        } else {
          this.editedItem.inventoryId = this.inventoryProperty.map(el=> el.id).slice();
        }
      });
    },
    onViewInventoryListing({ id }) {
      this.$router.push(`/inventory/${id}`);
    },
    diffDays(date) {
      if (date) {
        let a = moment(new Date());
        let b = moment(date);
        let diffDays = a.diff(b, "days");
        return diffDays;
      }
    },
    onViewDestination(destination) {
      this.$router.push(`alternative-destinations/${destination.id}`);
    },
    deleteItem() {
      // console.log("first");
      // this.editedIndex = this.alternativeDestinations.indexOf(item);
      // this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    async deleteItemConfirm() {
      this.loading = true;
      let doc = portalsApp
        .firestore()
        .collection("alternativeDestinations")
        .doc(this.editedItem.id);
      await doc.delete();
      this.closeDelete();
      this.loading = false;
      this.$router.push(`/alternative-destinations`);
    },
    closeDelete() {
      this.dialogDelete = false;
      // this.$nextTick(() => {
      //   this.editedItem = Object.assign({}, this.defaultItem);
      //   this.editedIndex = -1;
      // });
    },
    edit(item) {
      // this.editedIndex = this.alternativeDestinations.indexOf(item);
      // this.editedItem = Object.assign({}, item); // Extract the filename from the URL string
      this.docName = item.image;
      this.dialog = true;
    },
    onFileSelected(event) {
      // console.log(event.target.files[0]);
      const file = event.target.files[0];
      let img = {
        file: file,
        name: file.name.replace(" ", "-"),
        downloadUrl: URL.createObjectURL(file),
        dateSubmitted: new Date().toISOString(),
      };
      this.editedItem.image = img;
      this.docName = event.target.files[0].name;
    },
    close() {
      this.dialog = false;
      // this.$nextTick(() => {
      //   this.editedItem = Object.assign({}, this.defaultItem);
      //   this.editedIndex = -1;
      // });
    },
    async save() {
      if (!this.$refs.form.validate()) {
        await this.$store.dispatch("setLoading", false);
        await this.$store.dispatch("setSnackbar", {
          display: true,
          color: "error",
          text: "Please check form for errors",
          timeout: 10000,
        });
        return;
      }
      await this.$store.dispatch("setLoading", true);
      this.loading = true;
      this.editedItem.matchPackage = this.editedItem.name.split(/[,/]/)[0];
      
      let item = this.editedItem;
      // if (this.editedIndex > -1) {
      if (typeof item.image == "string") {
        item.inventoryId = JSON.stringify(item.inventoryId);
        let doc = portalsApp
          .firestore()
          .collection("alternativeDestinations")
          .doc(item.id);
        await doc.update(item);
        this.loading = false;
      } else {
        const img = item.image;
        const imgRef = mainApp
          .storage()
          .ref(`alternativeDestinations/${img.name}`);
        const pdfUploaded = await imgRef.put(img.file);
        item.image = await pdfUploaded.ref.getDownloadURL();
        delete img.file;
        item.inventoryId = JSON.stringify(item.inventoryId);
        let doc = portalsApp
          .firestore()
          .collection("alternativeDestinations")
          .doc(item.id);
        await doc.update(item);
        this.loading = false;
      }
      await this.$store.dispatch("setLoading", false);
      this.close();
    },
  },
};
</script>

<style>
tr {
  cursor: pointer;
}
.destination-detail {
  background-color: white;
  margin-bottom: 12px;
  border: thin solid rgba(0, 0, 0, 0.12);
}
</style>
